@media screen and (max-width: 405px) {
    :root {
        --tile-size: calc(90vw);
    }
}
@media screen and (min-width: 405px) {
    :root {
        --tile-size: 400px;
    }
}
html {
    scroll-behavior: smooth;
    font-size: 20px;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #5a5c81;
}
::-webkit-scrollbar-thumb {
    background: #313477;
}
::-webkit-scrollbar-thumb:hover {
    background: #242777;
}
.App {
    text-align: center;
    background-color: #1c1f25;
}
.App-logo {
    height: 20vmin;
    max-height: var(--tile-size);
    pointer-events: none;
}
h3 {
    margin: 10px;
}

h5 {
    margin: 10px;
}
.App-header {
    min-height: 33vh;
    max-height: 75vh;
    background-color: #1c1f25;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    transition: 1s;
    animation: fade-in 0.75s;
    z-index: 200;
    transform: translateZ(350px);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.App-link {
    color: #bfceff;
    margin-left: 10px;
    margin-right: 10px;
    background: none;
    border: none;
    padding: 10px;
    font-family: arial, sans-serif;
    cursor: pointer;
}
.App-navigation {
    min-height: 70px;
    width: 100%;
    max-width: 100vw;
    background-color: #282c47;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
    border-top: 10px solid #282c34;
    border-bottom: 10px solid #282c34;
    z-index: 201;
    transform: translateZ(350px);
    position: relative;
}
.App-nav-sticky {
    position: fixed;
    top: 0;
}
.App-nav-sticky + .App-content {
    padding-top: 124px;
}
.App-content {
    padding-top: 50px;
    display: block;
    background-color: #1c1f25;
    width: 100%;
    height: 100%;
}
.App-text-small {
    font-size: 18px;
    display:contents;
}
.App-about {
    max-width: 75vw;
    margin: auto;
}
.TileContainer {
    min-height: 20vmax;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: auto;
    padding-right: auto;
    justify-content: center;
    clear: both;
    position: relative;
    z-index: 1;
    transform-style: preserve-3d;
    transform: translateZ(0px) perspective(1000px);
}
#software {
    scroll-margin: 90px;
}
#about {
    color: white;
    scroll-margin: 90px;
}
#site-about {
    color: white;
    scroll-margin: 90px;
    background-color: #282c47;
    padding-top:30px;
    padding-bottom:30px;
    background-color: #1c1f25;
}
ul {
    list-style-type: none;
    padding:0px;
}
#contact {
    color: white;
    scroll-margin: 90px;
    padding-top:30px;
    padding-bottom:30px;
    min-height: 5vh;
    max-height: 5vh;
    width: 100%;
    max-width: 100vw;
    background-color: #282c47;
    border-top: 10px solid #282c34;
    border-bottom: 10px solid #282c34;
    display: flex;
    align-items:center;
    justify-content: center;
}
.copyright {
    font-size: 16px;
}
.App-link-button {
    background-color: white;
    color: black;
    max-width: 80vw;
    border: 2px solid #141820;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    overflow-wrap: anywhere;
}
.App-button {
    padding: 10px;
    font-size: 20px;
    background-color: #6a67ce;
    color: #fff;
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}
.App-button:disabled {
    color: #999;
    cursor: progress;
}