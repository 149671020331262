.Blockworld-div {
    width: 100%;
    height: calc(100% - 50px);
    top: 50px;
    position: absolute;
}
.Blockworld-span {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 50;
    display: block;
    color: white;
}
#heldItem2d {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-width: var(--tile-size);
    max-width: 600px;
    max-height: calc(var(--tile-size) - 50px);
    transform-origin: 100% 0;
    overflow: hidden;
    
}
.heldItem2dImg {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: var(--tile-size);
    max-height: calc(var(--tile-size) - 50px);
}
.pickaxe {
    margin-left: 20%;
}
#instructions {
    top: 0px;
}

#info {
    top: 10px;
}

#infoVal {
    top: 20px;
}

#aimVal {
    top: 30px;
}

#blockVal {
    top: 40px;
}

#positionVal {
    top: 50px;
}

.Blockworld-inventory-item-selected {
    outline: 2px solid white;
}

.Blockworld-inventory-item {
    color: white;
    position: absolute;
    background-color: rgba(48, 48, 48, 1);
    margin-top: auto;
    margin-bottom: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 50px;
    height: 50px;
}

#inventory {
    color: white;
    background-color: rgba(48, 48, 48, .5);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 30px;
    min-width: 40%;
    max-width: 80%;
    height: 54px;
    z-index: 50;
    outline: 2px solid white;
}