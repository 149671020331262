.Tile-clicked .Tile-inner {
    /* cursor: move;
    cursor: grab; */
    background-color: #393d9a;
    position: relative;
    transform: rotateY(180deg);
}


.Tile-front:hover {
    background-color: #4c50ac;
    /* max-width: 300px; */
    /* max-height: 300px; */
    /* position: relative; */
    transition: 0.6s;
    transform: scale(1) translateZ(1px) perspective(1000px);
}

.Tile {
    background-color: transparent;
    width: var(--tile-size);
    height: var(--tile-size);
    perspective: 1000px;
    position: relative;
    transform-style: preserve-3d;
}

.Tile-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    animation: grow-tile 0.75s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transform-style: preserve-3d;
}

.Tile-front {
    min-width: var(--tile-size);
    min-height: var(--tile-size);
    background-color: #393d9a;
    position: relative;
    transition: 0.6s;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    transition-duration: .25s;
    border: 1px solid #242777;
    backface-visibility: hidden;
    margin: auto;
    overflow: hidden;
    animation: grow-tile 0.75s;
    transform: translateZ(0px) perspective(1000px);
    cursor: pointer;
}

.Tile-back {
    min-width: 200px;
    min-height: 200px;
    width: var(--tile-size);
    height: var(--tile-size);
    /* display: block; */
    background-color: #4649ac;
    position: absolute;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    transition-duration: .25s;
    border: 1px solid #242777;
    clear: both;
    backface-visibility: hidden;
    transform: rotateY(180deg) translateZ(1px);
    /* animation: grow-tile 0.75s; */
    /* transform-style: preserve-3d; */
}

.Tile-nav-bar {
    background-color: #4649ac;
    height: 30px;
    width: 100%;
}

.Tile-nav-button-selected {
    background-color: #5456a1 !important;
}

.Tile-nav-button:hover {
    background-color: #24278b;
    cursor: pointer;
}

.Tile-nav-button, .Tile-nav-button:focus, .Tile-nav-button:active {
    float: left;
    background-color: #1b1e77;
    width: 30%;
    height: 30px;
    border: 1px solid black;
    background: none;
    outline: none;
    padding: 0;
}

.Tile-nav-button span {
    position: relative;
}

.Tile-close:hover {
    background-color: #24278b;
    cursor: pointer;
}

.Tile-close {
    float: right;
    text-align: center;
    background: none;
    outline: none;
    color: #181b1f;
    filter: drop-shadow(0px 0px 0px white);
    width: 10%;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    border: 1px solid;
    padding: 2px;
    cursor: pointer;
    z-index: 100;
}

.scrollbar-shifted {
    padding-right: 26px;
}

.Tile-background-image {
    width: calc(var(--tile-size) - 20px);
    height: calc(var(--tile-size) - 20px);
    position: relative;
    opacity: 0.5;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.Tile-text-container {
    color: white;
    position: absolute;
    margin: auto;
    min-height: 30px;
    display: inline-block;
    position: absolute;
    vertical-align: middle;
    line-height: 1;
    width: var(--tile-size);
    top: 50%;
    bottom: 50%;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.Tile-name {
    font-size: 30px;
    display: block;
    text-shadow: 0 0 10px #181b1f;
    overflow-wrap: anywhere;
    /* max-width: 100vw; */
}

.Tile-subtext {
    font-size: 20px;
    display: block;
    text-shadow: 0 0 10px #181b1f;
}

@keyframes grow-tile {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}