.App-read-wiki {
    font-family: arial;
    /* background: #036; */
    text-align: center;
    color: #b0c4de;
    position: absolute;
    top: 50px;
    left: 0px;
    width:100%;
    height: calc(var(--tile-size) - 50px);
    overflow-y: auto;
}

.App-read-wiki-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    /* grid-template-columns: repeat(auto-fill, 100px); */
}
.read-wiki-button:hover {
    background-color: #4e5c8a;
    transition: 0.6s;
}
.read-wiki-button {
    transition: 0.6s;
    color: #bfceff;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    background: none;
    border: none;
    padding: 10px;
    font-family: arial, sans-serif;
    cursor: pointer;
    outline:none;
}
/* .read-wiki-button {
    padding: 10px;
    background-color: #6a67ce;
    color: #fff;
    border: 0;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
} */

.output {
    background-color: #f9f9f9;
    padding: 10px;
    max-width: 600px;
    max-width: 90%;
    line-height: 30px;
    margin: auto;
    margin-top: 20px;
    color: #000;
    background: #b0c4de;
    border-radius: 10px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

#continuousDiv {
    background: #6a67ce;
    display: inline;
    border-radius: 5px;
    padding: 10px;
    line-height: 30px;
    cursor: pointer;
    color: #fff;
    /* margin: auto; */
    margin-right: 7px
}

#continuousButton {
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: auto;
}

#speedDiv {
    background: #6a67ce;
    display: inline;
    border-radius: 5px;
    padding: 10px;
    margin: auto;
    /* height: 54px; */
    line-height: 30px;
    color: #fff;
}