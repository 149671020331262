.App-tile-contents {
    font-family: arial;
    text-align: center;
    color: #b0c4de;
    position: absolute;
    top: 50px;
    width: calc(var(--tile-size) - 20px);
    height: calc(var(--tile-size) - 50px);
    padding-left: 10px;
    padding-right: 10px;
    overflow-y: auto;
    overflow-wrap: anywhere;
}

.App-tile-contents img {
    display: block;
    max-width: var(--tile-size);
    max-height: calc(var(--tile-size) - 50px);
    width: auto;
    height: auto;
}