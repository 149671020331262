.App-nav-link:hover {
    background-color: #4e5c8a;
    transition: 0.6s;
}
.App-nav-link {
    transition: 0.6s;
    color: #bfceff;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    background: none;
    border: none;
    font-size: 20px;
    font-family: arial, sans-serif;
    cursor: pointer;
    outline:none;
}