#fruit-classifier {
    /* background-color: #225; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: var(--tile-size);
    position: absolute;
    top: 50px;
    left: 0px;
    width:100%;
    height: calc(var(--tile-size) - 50px);
    overflow: auto;
}

#fruit-image-div {
    flex-direction: row;
    flex-wrap: wrap;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

#fruit-image,
#fruit-image img,
#fruit-image g {
    font: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white
}

#next-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}